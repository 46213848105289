import React from 'react';

import Layout from '../components/layout/main';

import ComingSoon from '../components/widgets/coming-soon';

import ContactUs from '../components/widgets/contact-us';
import GettingStarted from '../components/widgets/getting-started';
import Head from '../components/layout/head';

const HomeJobReferences = () => (
  <Layout>
    <Head title={'Home Job References'} />

    <ComingSoon />

    <ContactUs />
    <GettingStarted />
  </Layout>
);

export default HomeJobReferences;
