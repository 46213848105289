import React from 'react';

const ComingSoon = () => {
  return (
    <div className="box-container py-5">
      <div className=" text-center">
        <h1 className="fs-60 ganen-green-bold mb-3 mb-md-5">Coming Soon</h1>

        <h3 className="fs-35">SIT TIGHT !!!</h3>
        <div className="fs-22">Page content will be coming soon!</div>
      </div>
    </div>
  );
};

export default ComingSoon;
