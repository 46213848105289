import React from 'react';

import Pricing from '../../images/pricing.svg';
import Sched from '../../images/sched.svg';
import DownloadApp from '../../images/download-app-2.svg';

const GettingStartedSub = ({ image, text1, text2 }) => {
  return (
    <div className="text-center py-2 h-100 d-flex flex-column">
      <div>{image}</div>

      <h4 className="fs-24 ganen-green-bold mx-auto mh-60">{text1}</h4>

      <div className="text-center">{text2}</div>
    </div>
  );
};

const GettingStarted = ({ sectionClass }) => {
  return (
    <section className={`${sectionClass || ''}`}>
      <div className="box-container bg-ganen-gray-3 py-5 my-5 px-3">
        <h2 className="fs-42 ganen-green-bold text-center pb-4">
          Getting started with GANEN
        </h2>
        <div className="row d-flex">
          <div className="col-md-4 flex-grow-1">
            <GettingStartedSub
              image={
                <Pricing
                  height={55}
                  className="mb-5 bg-ganen-green xs-h-105"
                  fill="#2f4b0a"
                  color="#2f4b0a"
                />
              }
              text1="1. Call for pricing"
              text2="We’ll help you customize a home automation system to fit your lifestyle."
            />
          </div>

          <div className="col-md-4 flex-grow-1">
            <GettingStartedSub
              image={
                <Sched
                  height={55}
                  className="mb-5 bg-ganen-green xs-h-105"
                  fill="#2f4b0a"
                  color="#2f4b0a"
                />
              }
              text1="2. Schedule your installation"
              text2="We’ll send Smart Home Pros to professionally install your new system."
            />
          </div>

          <div className="col-md-4 flex-grow-1">
            <GettingStartedSub
              image={
                <DownloadApp
                  height={55}
                  className="mb-5 bg-ganen-green xs-h-105"
                  fill="#2f4b0a"
                  color="#2f4b0a"
                />
              }
              text1="3. Download the app"
              text2="All of our products work together to connect your home, inside and out. Best of all, you can secure your home and check-in from anywhere with the convenient GANEN Smart Home app."
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default GettingStarted;
